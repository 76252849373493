const mutations = {
	SET_USER(state, payload) {
		state.user = payload;
	},
	SET_LOGO(state, payload) {
		state.logo = payload;
	},
	SET_NAME(state, payload) {
		state.name = payload;
	}
};

export default mutations;
