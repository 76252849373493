<template>
	<div id="app">
		<div class="layout--main" :class="[navbarClasses, { 'app-page': isAppPage }]">
			<div id="content-overlay" />

			<vs-popup class="holamundo" title="Oops !" :active.sync="showError">
				<p>
					Une erreur est survenue, essayez de rafraichir la page. Si le problème persiste merci de nous en
					faire part.
				</p>
				<vs-collapse>
					<vs-collapse-item>
						<div slot="header">Plus d'informations</div>
						<p class="whitespace-pre-line">
							Code d'erreur :
							{{ error === null ? "" : error.error.request.status }} Message :
							{{ error === null ? "" : error.error.message }} URL de l'appel :
							{{ error === null ? "" : error.error.request.responseURL }}
						</p>
					</vs-collapse-item>
				</vs-collapse>
				<vs-button @click="reload">Rafraichir</vs-button>
			</vs-popup>

			<div class="content-wrapper">
				<the-navbar />

				<vs-row
					class="soft"
					:style="{
						visibility: display_subtitle ? 'visible' : 'hidden',
						margin: display_subtitle ? '1.5rem 0 !important' : '-2rem 0 0 0 !important'
					}"
				>
					<vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="6">
						<h3
							:class="{
								'cursor-pointer hover:bg-primary py-3 px-6 rounded-full hover:text-white': true,
								active: $route.name === 'Apps',
								'text-grey': $route.name !== 'Apps'
							}"
							@click="$router.push('/')"
						>
							{{ $t("Entreprises") }}
						</h3>
					</vs-col>
					<vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6" class="vertical-separator">
						<h3
							:class="{
								'cursor-pointer hover:bg-primary py-3 px-6 rounded-full hover:text-white': true,
								active: $route.name === 'Accountants',
								'text-grey': $route.name !== 'Accountants'
							}"
							@click="$router.push('/accountants')"
						>
							{{ $t("Collaborateurs") }}
						</h3>
					</vs-col>
				</vs-row>

				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
	import themeConfig from "@/../themeConfig.js";
	import TheNavbar from "./layouts/components/TheNavbar";
	import { mapState } from "vuex";

	export default {
		components: {
			TheNavbar
		},
		data() {
			return {
				navbarType: themeConfig.navbarType || "sticky",
				navbarColor: themeConfig.navbarColor || "#fff",
				routerTransition: themeConfig.routerTransition || "none",
				isNavbarDark: false,
				routeTitle: this.$route.meta.pageTitle,
				disableCustomizer: themeConfig.disableCustomizer,
				error: null,
				showError: false
			};
		},
		computed: {
			...mapState(["user"]),
			isAppPage() {
				if (this.$route.path.includes("/apps/")) {
					return true;
				} else {
					return false;
				}
			},
			navbarClasses() {
				return {
					"navbar-hidden": this.navbarType == "hidden",
					"navbar-sticky": this.navbarType == "sticky",
					"navbar-static": this.navbarType == "static",
					"navbar-floating": this.navbarType == "floating"
				};
			},
			display_subtitle() {
				return (
					this.user?.accounting_firm && (this.$route.name === "Accountants" || this.$route.name === "Apps")
				);
			}
		},
		mounted() {
			document.addEventListener("apiError", error => {
				console.log(error);

				this.error = error;
				this.showError = true;
			});
		},
		methods: {
			reload() {
				location.reload();
			}
		}
	};
</script>

<style>
	#app {
		background-color: var(--background);
	}
	.content-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
	.layout--full-page {
		flex-grow: 5;
		position: relative;
	}
	.layout--middle {
		position: absolute;
		top: 45%;
		transform: translateY(-50%);
	}
	.vs-component.vs-button:disabled {
		opacity: 1;
		filter: brightness(175%) grayscale(50%);
	}
	.vs-component.vs-button:hover {
		background-color: #137ec8;
	}
	.text-grey {
		color: var(--grey);
	}
	.active {
		text-decoration: underline !important;
		color: var(--dark);
	}
	.vertical-separator {
		border-left: 1px solid var(--grey);
		height: 100%;
	}
	.soft {
		transition: all 400ms;
	}
</style>
