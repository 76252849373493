/* =========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import VxCard from "./components/vx-card/VxCard.vue";
import FeatherIcon from "./components/FeatherIcon.vue";
import SButton from "./components/SButton.vue";

Vue.component(VxCard.name, VxCard);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(SButton.name, SButton);
