export default {
	getImageUrl: () => item => {
		if (item?.image) {
			const { image } = item;

			if (image.includes("http")) {
				return image;
			} else if (image.includes("/services/")) {
				return `https://sinao.app${image}`;
			} else {
				return `https://sinao-prod-storage.s3.eu-west-3.amazonaws.com/${image}`;
			}
		}
	},
	accounting_firm_apps(state) {
		if (!state.user?.accounting_firm) return [];

		return state.user.accounting_firm.users.reduce((accumulator, user) => {
			user.apps_by_policies.forEach(app => {
				if (accumulator.find(acc_app => acc_app.id === app.id) === undefined) {
					accumulator.push(app);
				}
			});
			user.apps_by_admin.forEach(app => {
				if (accumulator.find(acc_app => acc_app.id === app.id) === undefined) {
					accumulator.push(app);
				}
			});
			return accumulator;
		}, []);
	},
	accountant_policy_id(state) {
		return state.user.accounting_firm.policy_id;
	}
};
