var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"layout--main",class:[_vm.navbarClasses, { 'app-page': _vm.isAppPage }]},[_c('div',{attrs:{"id":"content-overlay"}}),_c('vs-popup',{staticClass:"holamundo",attrs:{"title":"Oops !","active":_vm.showError},on:{"update:active":function($event){_vm.showError=$event}}},[_c('p',[_vm._v(" Une erreur est survenue, essayez de rafraichir la page. Si le problème persiste merci de nous en faire part. ")]),_c('vs-collapse',[_c('vs-collapse-item',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Plus d'informations")]),_c('p',{staticClass:"whitespace-pre-line"},[_vm._v(" Code d'erreur : "+_vm._s(_vm.error === null ? "" : _vm.error.error.request.status)+" Message : "+_vm._s(_vm.error === null ? "" : _vm.error.error.message)+" URL de l'appel : "+_vm._s(_vm.error === null ? "" : _vm.error.error.request.responseURL)+" ")])])],1),_c('vs-button',{on:{"click":_vm.reload}},[_vm._v("Rafraichir")])],1),_c('div',{staticClass:"content-wrapper"},[_c('the-navbar'),_c('vs-row',{staticClass:"soft",style:({
					visibility: _vm.display_subtitle ? 'visible' : 'hidden',
					margin: _vm.display_subtitle ? '1.5rem 0 !important' : '-2rem 0 0 0 !important'
				})},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"right","vs-align":"center","vs-w":"6"}},[_c('h3',{class:{
							'cursor-pointer hover:bg-primary py-3 px-6 rounded-full hover:text-white': true,
							active: _vm.$route.name === 'Apps',
							'text-grey': _vm.$route.name !== 'Apps'
						},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" "+_vm._s(_vm.$t("Entreprises"))+" ")])]),_c('vs-col',{staticClass:"vertical-separator",attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"center","vs-w":"6"}},[_c('h3',{class:{
							'cursor-pointer hover:bg-primary py-3 px-6 rounded-full hover:text-white': true,
							active: _vm.$route.name === 'Accountants',
							'text-grey': _vm.$route.name !== 'Accountants'
						},on:{"click":function($event){return _vm.$router.push('/accountants')}}},[_vm._v(" "+_vm._s(_vm.$t("Collaborateurs"))+" ")])])],1),_c('router-view')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }