import Vue from "vue";

// Vuex Store
import store from "./store/store";

// Vue Router
import router from "./router";

// Vue i18n
import i18n from "./i18n";

// Sentry
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		Vue,
		dsn: "https://001122f6e85e47be85b0353719bc0c76@o326214.ingest.sentry.io/5619168",
		autoSessionTracking: true,
		logErrors: true,
		tracesSampleRate: 1.0,
		environment: process.env.NODE_ENV,
		integrations: [
			new Integrations.BrowserTracing()
			/*new SentryRRWeb({
				recordLog: true
				collectFonts: true,
				recordCanvas: true
			})*/
		],
		tracingOptions: {
			trackComponents: true
		}
	});
}
// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; // Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate, {
	mode: "eager"
});

import VueInputAutowidth from "vue-input-autowidth";
Vue.use(VueInputAutowidth);

// axios
import axios from "axios";
Vue.prototype.$http = axios;

// Feather font icon
require("./assets/css/iconfont.css");

Vue.config.productionTip = false;
Vue.config.devtools = true;

// beforeQuery(() => store.commit("SET_LOADING", true));
// afterQuery(() => store.commit("SET_LOADING", false));

// Load app
import App from "./App.vue";

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount("#app");
