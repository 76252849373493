<!-- =========================================================================================
    File Name: FeatherIcon.vue
    Description: Feather icon component. Creates feather icon based on 'icon' prop
    Component Name: FeatherIcon
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<script>
	import * as icons from "vue-feather-icons";

	export default {
		name: "FeatherIcon",
		props: {
			icon: { type: String, required: true },
			svgClasses: { type: [String, Object, Array], default: "" },
			badge: {}
		},
		methods: {
			clickFeather(event) {
				this.$emit("click", event);
			},
			mouseoverFeather(event) {
				this.$emit("mouseover", event);
			},
			mouseoutFeather(event) {
				this.$emit("mouseout", event);
			}
		},
		render(h) {
			const svg = h(icons[this.icon], { class: this.svgClasses });
			const badgeEl = h(
				"span",
				{
					class: "feather-icon-badge bg-primary text-white absolute px-2 py-1 rounded-full text-xs",
					style: "top: -9px; right: -7px"
				},
				[this.badge]
			);
			const attr = {
				class: ["feather-icon select-none relative"],
				on: {
					click: this.clickFeather,
					mouseover: this.mouseoverFeather,
					mouseout: this.mouseoutFeather
				}
			};
			const children = [svg];

			if (this.badge) {
				children.push(badgeEl);
			}

			return h("span", attr, children);
		}
	};
</script>

<style>
	@import "../assets/scss/vuesax/components/featherIcon.scss";
</style>
