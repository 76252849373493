import { render, staticRenderFns } from "./SButton.vue?vue&type=template&id=b5e5adfe&scoped=true&lang=html"
import script from "./SButton.vue?vue&type=script&lang=js"
export * from "./SButton.vue?vue&type=script&lang=js"
import style0 from "./SButton.vue?vue&type=style&index=0&id=b5e5adfe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5e5adfe",
  null
  
)

export default component.exports