import Vue from "vue";
import VueI18n from "vue-i18n";

// Importer les messages de traduction
import en from "./locales/en.json";
import fr from "./locales/fr.json";
import es from "./locales/es.json";
import it from "./locales/it.json";

Vue.use(VueI18n);

const messages = {
	en: en,
	fr: fr,
	es: es,
	it: it
};

const i18n = new VueI18n({
	locale: "fr",
	messages
});

export default i18n;
